import React from 'react';

function ImprintDE() {
  return (
    <div className="tm-imprint">
      <h1>Impressum.</h1>
      <p>
        <b>Gesamtverantwortung Trafimage.</b>
        <br />
        <br />
        SBB AG
        <br />
        Infrastruktur – Anlagen und Technologie – Operation Center Technik –
        Technical Management
        <br />
        Fachbus Trafimage
        <br />
        Poststrasse 6
        <br />
        3072 Ostermundingen
        <br />
        Schweiz
        <br />
        <a href="http://www.trafimage.ch">www.trafimage.ch</a>
        <br />
        <a href="mailto:trafimage@sbb.ch">trafimage@sbb.ch</a>
        <br />
      </p>
      <p>
        <b>Karten und - Bahnhofplanerstellung.</b>
        <br />
        <br />
        evoq communications AG
        <br />
        Ottikerstrasse 59
        <br />
        8006 Zürich
        <br />
        <a href="http://www.evoq.ch">www.evoq.ch</a>
        <br />
      </p>
      <p>
        <b>
          IT-Koordination, Daten öffentlicher Verkehr, Interaktiver Bahnhofplan.
        </b>
        <br />
        <br />
        geOps AG
        <br />
        Solothurnerstrasse 235
        <br />
        4600 Olten
        <br />
        <a href="http://www.geops.ch">www.geops.ch</a>
        <br />
      </p>
      <p>
        <b>CAAD und Workspace Bahnhofpläne.</b>
        <br />
        <br />
        multipol
        <br />
        Luegislandstrasse 105
        <br />
        8051 Zürich
        <br />
        <a href="http://www.multipol.ch">www.multipol.ch</a>
        <br />
      </p>
    </div>
  );
}

export default ImprintDE();
