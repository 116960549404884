import React from 'react';

function LegalFR() {
  return (
    <div className="tm-imprint">
      <h1>Aspects juridiques.</h1>

      <p>
        <b>Mention juridique et protection des données.</b>
      </p>
      <p>
        La{' '}
        <a href="http://www.cff.ch/meta/mention-juridique.html">
          Mention juridique
        </a>{' '}
        et les{' '}
        <a href="http://www.cff.ch/meta/mention-juridique.html">
          dispositions sur la protection des données
        </a>{' '}
        des CFF s’appliquent.
        <p>
          En dérogation aux dispositions générales des CFF en matière de
          protection des données, maps.trafimage.ch enregistre des données
          d’utilisation non personnelles telles que votre adresse IP, la
          dernière page visitée, le logiciel de navigation utilisé, la date,
          l’heure, etc. et les exploite sous forme anonymisée avec Matomo («
          <a
            href="http://matomo.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Matomo
          </a>
          »).
        </p>
      </p>

      <p>
        <b>Image de Trafimage.</b>
      </p>
      <p>
        L’identité visuelle de Trafimage (constitution et composition de
        l’image) fait partie intégrante de la présentation des CFF et est
        protégée par un droit d’auteur. Les cartes et les plans contenus dans
        les sites Internet de Trafimage, y compris les informations y relatives,
        sont mis à disposition exclusivement à des fins d’usage privé et
        d’information.
      </p>

      <p>
        <b>
          Utilisation des cartes Trafimage et des données topographiques de
          swisstopo et OpenStreetMap.
        </b>
      </p>
      <p>
        Pour les géodonnées de base (vues aériennes, relief, cartes nationales),
        ce sont les dispositions de swisstopo et OpenStreetMap qui s’appliquent.
        Il est permis de télécharger, d’enregistrer, d‘imprimer, ou de
        reproduire sous toute autre forme, certaines cartes et plans et/ou des
        extraits ainsi que des informations, à condition de ne supprimer ni des
        mentions de copyright «© CFF/géodonnées: © OpenStreetMap contributors, ©
        swisstopo (5704003351)» ni d’autres désignations légalement protégées.
        Les CFF conservent tous les droits de propriété ou bien d’utilisation
        (carte-image), de même que swisstopo (bases des cartes). Cette
        autorisation peut être révoquée à tout moment. Toute autre utilisation
        (en totalité ou en partie), transmission (par voie électronique ou avec
        d’autres moyens), modification, mise en lien ou utilisation des
        géodonnées de base nécessite l’autorisation préalable expresse des CFF
        et de swisstopo.
      </p>

      <p>
        <b>Copyright swisstopo.</b>
      </p>
      <p>
        Les géodonnées de base intégrées à cette plate-forme Internet sont la
        propriété de la Confédération suisse et protégées par un droit d’auteur,
        conformément à la loi fédérale sur la géoinformation (RS 510.62). Sauf
        indication contraire, il s’agit de données de l’Office fédéral de
        topographie (swisstopo) et les dispositions de l’Ordonnance sur la
        géoinformation (RS 510.620) ainsi que l’Ordonnance du DDPS sur les
        émoluments de l’Office fédéral de topographie (RS 510.620.2)
        s’appliquent.
      </p>

      <p>
        <b>Responsabilité swisstopo.</b>
      </p>
      <p>
        Si un contrat a été signé entre swisstopo et l’utilisateur d’un service,
        la responsabilité de swisstopo n’est engagée que pour des dommages liés
        à sa négligence ou causés intentionnellement et relevant de son domaine
        de compétence. Swisstopo ne peut être tenu pour responsable de toute
        perte de gain, toute perte de données ou tout autre dommage indirect,
        imputables notamment à une erreur d’information ou une panne technique.
        swisstopo décline toute responsabilité pour toute erreur ne relevant pas
        de son domaine de compétence.
      </p>
      <p>
        <b>Données des CFF et de leurs partenaires.</b>
      </p>
      <p>
        Toutes les autres données sont la propriété des CFF ou de leurs
        partenaires.
      </p>
    </div>
  );
}

export default LegalFR();
