import React from 'react';

function LegalDE() {
  return (
    <div className="tm-imprint">
      <h1>Rechtliches.</h1>

      <p>
        <b>Rechtlicher Hinweis und Datenschutz.</b>
      </p>
      <p>
        Es gelten die{' '}
        <a href="http://www.sbb.ch/meta/rechtlicher-hinweis.html">
          rechtlichen Hinweise
        </a>{' '}
        und{' '}
        <a href="http://www.sbb.ch/meta/datenschutz.html">
          Datenschutzbestimmungen
        </a>{' '}
        der SBB.
        <p>
          In Abweichung zur den allgemeinen Datenschutzbestimmungen der SBB
          werden auf maps.trafimage.ch unpersönliche Nutzungsdaten wie Ihre
          IP-Adresse, die zuletzt besuchte Seite, der verwendete Browser, Datum,
          Uhrzeit etc. gespeichert und mit Matomo («
          <a
            href="http://matomo.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Matomo
          </a>
          ») anonymisiert ausgewertet.
        </p>
        tomo/g
      </p>

      <p>
        <b>Erscheinungsbild von Trafimage</b>
      </p>
      <p>
        Das Erscheinungsbild von Trafimage (Bildaufbau und Bildgestaltung) ist
        ein fester Bestandteil des Auftritts der SBB und ist urheberrechtlich
        geschützt. Die auf den Websites von Trafimage enthaltenen Karten und
        Pläne inklusive den dazugehörenden Informationen werden ausschliesslich
        für den privaten Gebrauch sowie zu Informationszwecken bereit gestellt.
      </p>

      <p>
        <b>
          Nutzung Trafimage-Kartenbild und Kartendaten von swisstopo und
          OpenStreetMap.
        </b>
      </p>
      <p>
        Für die Geobasisdaten (z.B. Luftbilder, Relief, Landeskarten) gelten die
        Bestimmungen von swisstopo und OpenStreetMap. Es ist gestattet, einzelne
        Karten und Pläne bzw. Ausschnitte davon und Informationen für private
        Zwecke herunterzuladen, abzuspeichern, auszudrucken oder auf andere
        Weise zu vervielfältigen sofern weder der Copyrightvermerke “©
        SBB/CFF/FSS, Geodaten: © OpenStreetMap contributors, © swisstopo
        (5704003351)“ noch andere gesetzlich geschützte Bezeichnungen entfernt
        werden. Sämtliche Eigentums- bzw. Nutzungsrechte verbleiben bei der SBB
        (Kartenbild) und bei der swisstopo (Kartengrundlagen). Sie können diese
        Zustimmung jederzeit widerrufen. Jegliche weitere Nutzung (vollständig
        oder teilweise), Übermittlung (elektronisch oder mit anderen Mitteln),
        Modifikation, Verknüpfung oder Benutzung der in den Websites enthaltenen
        Geobasisdaten ist nur mit vorheriger schriftlicher Einwilligung von SBB
        und swisstopo gestattet.
      </p>

      <p>
        <b>Copyright swisstopo.</b>
      </p>
      <p>
        Die in dieser Internet-Plattform integrierten Geobasisdaten sind
        Eigentum der Schweizerischen Eidgenossenschaft. Die Daten sind
        urheberrechtlich durch das Bundesgesetz über Geoinformation (SR 510.62)
        geschützt. Sofern nichts anderes vermerkt ist, handelt es sich um Daten
        des Bundesamtes für Landestopografie (swisstopo) und es gelten die
        Bestimmungen der Geoinformationsverordnung (SR 510.620) und die
        Verordnung des VBS über die Gebühren des Bundesamtes für
        Landestopografie (SR 510.620.2).
      </p>

      <p>
        <b>Haftung von swisstopo.</b>
      </p>
      <p>
        Liegt ein Vertragsverhältnis zwischen swisstopo und dem Nutzer eines
        Dienstes vor, so haftet swisstopo nur für Schäden, die sie
        grobfahrlässig oder vorsätzlich verursacht hat und im
        Verantwortungsbereich von swisstopo liegen. swisstopo haftet nicht für
        entgangenen Gewinn, Datenverluste oder sonstige indirekte Schäden, die
        namentlich durch Fehlinformationen oder technische Störungen entstanden
        sind. swisstopo übernimmt keine Verantwortung für Fehler, die nicht in
        Ihrem Verantwortungsbereich liegen.
      </p>

      <p>
        <b>Daten der SBB und ihrer Partner.</b>
      </p>
      <p>Alle anderen Daten sind Eigentum der SBB oder ihrer Partner.</p>
    </div>
  );
}

export default LegalDE();
