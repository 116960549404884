import React from 'react';

function LegalIT() {
  return (
    <div className="tm-imprint">
      <h1>Aspetti legali.</h1>

      <p>
        <b>Avvertenza legale e protezione dei dati.</b>
      </p>
      <p>
        Valgono le{' '}
        <a href="http://www.ffs.ch/meta/note-legali.html">note legali</a> e{' '}
        <a href="http://www.ffs.ch/meta/tutela-dei-dati.html">
          la dichiarazione sulla protezione dei dati
        </a>{' '}
        <p>
          In deroga alle disposizioni generali in materia di protezione dei dati
          delle FFS, su maps.trafimage.ch i dati di utilizzo non personali come
          gli indirizzi IP, l’ultima pagina visitata, il browser utilizzato, la
          data, l’ora, ecc. vengono salvati e analizzati in modo anonimo con
          Matomo («
          <a
            href="http://matomo.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Matomo
          </a>
          »).
        </p>
      </p>

      <p>
        <b>Brand Trafimage.</b>
      </p>
      <p>
        Il brand Trafimage (struttura e presentazione delle immagini) è parte
        integrante del brand FFS e pertanto è protetto dal diritto d’autore. Le
        cartine e i piani contenuti nel sito web di Trafimage, comprese le
        relative informazioni, sono disponibili esclusivamente per uso privato e
        per scopi informativi.
      </p>

      <p>
        <b>
          Utilizzo dell’immagine cartografica Trafimage e dei dati swisstopo ed
          OpenStreetMap.
        </b>
      </p>
      <p>
        Per i geodati di base (p. es. immagini aeree, rilievi, carte
        topografiche) valgono le norme di swisstopo ed OpenStreetMap. A titolo
        privato è permesso scaricare, salvare, stampare o riprodurre in altro
        modo singole cartine e singoli piani o parte di essi e le relative
        informazioni, a condizione che non siano rimosse le indicazioni circa la
        fonte «© FFS / Geodati: © OpenStreetMap contributors, © swisstopo
        (5704003351)» né altre indicazioni tutelate per legge. Tutti i diritti
        d’autore rispettivamente di utilizzo rimangono alle FFS (immagine
        cartografica) e a swisstopo (dati cartografici), che possono in
        qualsiasi momento revocare il proprio assenso all’utilizzo. Qualsiasi
        ulteriore utilizzo (completo o parziale), trasmissione (elettronica o
        con altri mezzi), modifica, collegamento o impiego dei geodati di base
        contenuti nelle pagine web sono consentiti solo previo consenso scritto
        da parte di FFS e swisstopo.
      </p>

      <p>
        <b>Copyright swisstopo.</b>
      </p>
      <p>
        I geodati di base inclusi in questa piattaforma Internet sono proprietà
        della Confederazione svizzera. In virtù della Legge federale sulla
        geoinformazione (RS 510.62) questi dati godono di tutela giuridica per
        quanto riguarda il diritto d’autore. Se non indicato altrimenti, si
        tratta di dati dell’Ufficio federale di topografia (swisstopo) e quindi
        disciplinati dalle norme dell’Ordinanza sulla geoinformazione (OGI, RS
        510.620) e dall’Ordinanza del DDPS sugli emolumenti dell’Ufficio
        federale di topografia (RS 510.620.2). Responsabilità di swisstopo. In
        presenza di un rapporto contrattuale tra swisstopo e l’utente di un
        servizio, swisstopo è responsabile solo dei danni che ha causato
        negligentemente o intenzionalmente nell’ambito delle proprie competenze.
        swisstopo declina ogni responsabilità per danni da mancato guadagno,
        perdita di dati o altri danni indiretti sorti in seguito a informazioni
        errate o guasti tecnici. swisstopo declina altresì ogni responsabilità
        per errori che esulano dall’ambito delle sue competenze.
      </p>

      <p>
        <b>Responsabilità di swisstopo.</b>
      </p>
      <p>
        In presenza di un rapporto contrattuale tra swisstopo e l’utente di un
        servizio, swisstopo è responsabile solo dei danni che ha causato
        negligentemente o intenzionalmente nell’ambito delle proprie competenze.
        swisstopo declina ogni responsabilità per danni da mancato guadagno,
        perdita di dati o altri danni indiretti sorti in seguito a informazioni
        errate o guasti tecnici. swisstopo declina altresì ogni responsabilità
        per errori che esulano dall’ambito delle sue competenze.
      </p>

      <p>
        <b>Dati delle FFS e dei suoi partner.</b>
      </p>
      <p>Tutti gli altri dati sono proprietà delle FFS o dei suoi partner.</p>
    </div>
  );
}

export default LegalIT();
